$(function() {

    var dirRtlFlag = false;
    if ($("html").attr('dir') == 'rtl') {
      dirRtlFlag = true;
    }
  
    $('#news-slick').slick({
      dots: true,
      infinite: true,
      speed: 1000,
      fade: true,
      rtl: dirRtlFlag,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      cssEase: 'linear',
      pauseOnHover: false,
      adaptiveHeight: true
    });
  });