$(document).ready(function() {
	$('.main-gallery').lightGallery({
		thumbnail: true,
		animateThumb: false,
		showThumbByDefault: false,
		selector: '.gallery-item'
	});


	// function add() {
	// 	$(items[i]).addClass('gallery-item_long');
	// }

	// var items = $('.main-gallery').children();
	// var long1 = 1;
	// var long2 = 3;
	// var long3 = 8;
	// var step = 9;

	// for (var i = 0; i < items.length; i++) {
	// 	switch (i) {
	// 		case long1:
	// 			add();
	// 			long1 += step;
	// 			break;
	// 		case long2:
	// 			add();
	// 			long2 += step;
	// 			break;
	// 		case long3:
	// 			add();
	// 			long3 += step;
	// 			break;
	// 	}
	// }
})