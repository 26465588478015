$(function(){
  //##### section aside panel form
  $('.aside-panel-tab').on('click', function(){
    var asidePanel = $(this).closest('.wrapper-all-aside-panel');
    if ($(asidePanel).hasClass('aside-panel__open')) {
      $(asidePanel).removeClass('aside-panel__open');
    } else {
      $(asidePanel).addClass('aside-panel__open');
    }
  });//#####  END section aside panel form
}); 